import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import ImageSlider from "../components/image-slider";
import Footer from "../components/footer";
import "./home.css";

const Home = (props) => {
  useEffect(() => {
    // Function to scroll to the top of the page
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    // Scroll to the top when the component mounts (page loads)
    scrollToTop();
  }, []); // Empty dependency array to only run this effect once

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const style = {
    marginTop: "5rem",
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Indonesia di Hatiku</title>
        <meta property="og:title" content="Indonesia di Hatiku" />
      </Helmet>
      <Header rootClassName="header-root-class-name1"></Header>
      {/* <AutoplaySlider
        style={{ aspectRatio: "4.5/2", style }}
        organicArrows={true}
        bullets={true}
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={4000}
      >
        <div data-src="/Asset/slide1.webp" />
        <div data-src="/Asset/slide2.webp" />
        <div data-src="/Asset/slide3.webp" />
      </AutoplaySlider> */}
      <ImageSlider rootClassName="image-slider-root-class-name"></ImageSlider>

      <div id="profile" className="home-profile-saya">
        <h1 className="home-text">Profil Saya</h1>
        <span className="home-text001">
          <span>
            Rev. Dr. Suzette Hattingh, yang juga dikenal sebagai “Mama Papua”,
            pertama kali jatuh cinta pada Papua saat sedang melakukan KKR
            pertamanya di stadion Jayapura pada tahun 1997. Namun, baru pada
            tahun 2010 beliau benar-benar mengambil kembali panggilannya atas
            Papua, suatu beban yang tidak pernah hilang daripadanya selama
            bertahun-tahun.
          </span>
          <br></br>
          <br></br>
          <span>
            Telah berada dalam ladang pelayanan penuh waktu sejak tahun 1998, di
            mana setelah menyelesaikan sekolah Alkitab pertamanya, beliau mulai
            menjangkau bangsa-bangsa di bawah bimbingan Ev. Reinhard Bonnke,
            Christ for All Nation selama 18 tahun, dan berkeliling ke 68 negara.
          </span>
          <br></br>
          <br></br>
          <span>
            Beliau mendirikan pelayanannya sendiri pada tahun 1997, yang bernama
            Voice in the City, dan KKR pertamanya diadakan di Papua, Indonesia.
          </span>
          <br></br>
          <span>
            Yayasan Cinta Papua didirikan pada tahun 2015. Sejak saat itu, di
            Indonesia sendiri, beliau telah melayani di 65 kota, tercakup dalam
            34 wilayah, termasuk sebagian besar kota-kota yang ada di Papua dan
            beberapa diantaranya merupakan wilayah terpencil yang ada di Papua.
          </span>
          <br></br>
          <br></br>
          <span>
            Dalam sebuah ibadah, beliau sangat tergerak saat menyaksikan
            beberapa Gembala yang berasal dari pedalaman Papua berusaha keras
            untuk memahami pengajaran yang disampaikan.
          </span>
          <br></br>
          <br></br>
          <span>
            Saat sedang berdiri di balkon sebuah hotel di Jayapura, sambal
            berdoa bagi para Gembala tersebut, beliau menyadari ada sesuatu
            seperti “kilauan di atas air”. Awalnya beliau berpikir itu hanyalah
            pantulan sinar matahari, tetapi saat langit mulai ditutupi awan,
            kilauan itu tetap ada di sana, kemudian barulah beliau menyadari
            bahwa Tuhan sedang memberinya sebuah “penglihatan yang terbuka
            mengenai rencanaNya atas Papua”. Kilauan itu kemudian semakin besar
            seperti cahaya-cahaya kecil dan membentuk sebuah aliran yang
            memenuhi Indonesia dan bangsa-bangsa yang berawal mula dari Papua.
            Saat itulah hati beliau terbuka seperti sebuah vas yang sangat besar
            dan Tuhan mencurahkan sungai kasih ke dalam hatinya bagi sang
            “Mutiara Hitam dari Indonesia” dan kepada panggilanNya yaitu Papua.
          </span>
          <br></br>
          <br></br>
          <span>
            Resmi didirikan di Manokwari, Love Papua Centre ada khusus untuk
            menjangkau anak-anak yang ada dimasyarakat sekitarnya, para tahanan,
            dan memberikan pelatihan.
          </span>
          <br></br>
          <span>
            Tidak jauh dari pegunungan Arfak, cabang kedua dari Love Papua
            Centre resmi berdiri di Subsay. Tidak hanya sekadar menjadi tempat
            pelatihan kerohanian, tetapi juga sebagai tempat pelatihan
            agrikultural yang dipersiapkan bagi para siswa.
          </span>
          <br></br>
          <span>
            Rev. Dr. Suzette Hattingh katakan merasa sangat terhormat dijuluki
            sebagai “Mama Papua”. Semoga Tuhan menolong beliau agar layak
            menerima kehormatan seperti itu di depan umatNya.
          </span>
        </span>
      </div>
      <div className="home-ayat">
        <div className="home-image">
          <img alt="image" src="/Asset/koma.svg" className="home-image01" />
        </div>
        <div className="home-isi-ayat">
          <span className="home-text024">
            Sebab Aku ini mengetahui rancangan-rancangan apa yang ada pada-Ku
            mengenai kamu, demikianlah firman TUHAN, yaitu rancangan damai
            sejahtera dan bukan rancangan kecelakaan, untuk memberikan kepadamu
            hari depan yang penuh harapan. Dan apabila kamu berseru dan datang
            untuk berdoa kepada-Ku, maka Aku akan mendengarkan kamu; apabila
            kamu mencari Aku, kamu akan menemukan Aku; apabila kamu menanyakan
            Aku dengan segenap hati, Aku akan memberi kamu menemukan Aku,
            demikianlah firman TUHAN,
          </span>
          <h1 className="home-text025">Yeremia 29:11-14B</h1>
        </div>
      </div>
      <div id="visimisi" className="home-visi-misi-application">
        <div className="home-visi-dan-misi">
          <h1 className="home-text026">VISI &amp; MISI</h1>
          <span className="home-text027">
            <span>
              Sekarang kepada Dia Yang, dengan (sebagai akibat dari) kekuatan
              [Nya] yang bekerja di dalam diri kita, dapat [menjalankan
              tujuan-Nya dan] melakukan secara berlimpah, jauh melampaui semua
              yang kita [berani] minta atau berpikir [jauh melampaui doa,
              keinginan, pikiran, harapan, atau impian tertinggi kita] - Ef 3:20
              (diterjemahkan langsung dari Alkitab terjemahan Amplified Classic)
              Bagi Dialah, yang dapat melakukan jauh lebih banyak dari pada yang
              kita doakan atau pikirkan, seperti yang ternyata dari kuasa yang
              bekerja di dalam kita – Efesus 3:20 TB
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span>
              Inilah misi dan visi dari INDONESIA DI HATIKU – kesatuan gereja di
              dalam doa mempercayai Tuhan lebih dari apa yang pernah kita
              pikirkan atau minta untuk Indonesia sebagai sebuah bangsa. “Doa
              dan keinginan tertinggi” kita bagi INDONESIA DI HATIKU adalah agar
              kita sebagai umat Tuhan akan bersatu dalam doa, iman dan syafaat
              untuk kehendak Tuhan selama pemilu yang akan datang. Untuk
              transisi yang damai dari pemerintahan yang ada kepada pemerintahan
              baru yang terpilih dan kemakmuran umum di negeri kita.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span>
              Indonesia sangat dicintai oleh rakyatnya, sehingga misi dan visi
              dari INDONESIA DI HATIKU juga untuk mengobarkan semangat patriotik
              tersebut ke dalam doa dan penyembahan yang terarah. Mempercayai
              Tuhan untuk mencurahkan berkatNya melampaui apa yang dapat kita
              pikirkan atau minta
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </span>
        </div>
        <div className="home-application">
          <h1 className="home-text034">APPLICATION</h1>
          <span className="home-text027">
            <span>
              Reli besar doa 14 kota yang melibatkan sebanyak mungkin denominasi
              dan kelompok yang berbeda akan dilaksanakan mulai dari Bali hingga
              Jayapura - Papua, mencakup sebanyak mungkin wilayah di Indonesia.
              Dari konser dan reli doa satu malam, kita berharap dapat disiarkan
              ke wilayah masing-masing dengan melibatkan sebanyak mungkin
              gereja, kelompok, jaringan dan individu melalui media sosial dan
              internet.
            </span>
            <br></br>
            <br></br>
            <span>
              Tujuannya jelas: Indonesia yang ada dalam hati kita dibawa kepada
              Tuhan dalam doa dan penyembahan, sesuai dengan hati Tuhan.
            </span>
          </span>
        </div>
      </div>
      <div id="rallysmall" className="home-rally-doa-small">
        <h1 className="home-text039">Rally Doa</h1>
        <div className="home-row1">
          <div className="home-bali">
            <img
              alt="image"
              src="/RallyDoaNew/bali-500w.webp"
              className="home-image02"
            />
            <Link to="/bali" className="home-btn1 button">
              <span className="home-text040">
                <span>10 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image03"
              />
            </Link>
          </div>
          <div className="home-tasik">
            <img
              alt="image"
              src="/RallyDoaNew/tasik-500w.webp"
              className="home-image04"
            />
            <Link to="/tasikmalaya" className="home-btn101 button">
              <span className="home-text043">
                <span>13 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image05"
              />
            </Link>
          </div>
        </div>
        <div className="home-row2">
          <div className="home-bandung">
            <Link to="/bandung" className="home-btn102 button">
              <span className="home-text046">
                <span>14 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image06"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/bandung-500w.webp"
              className="home-image07"
            />
          </div>
          <div className="home-jakarta">
            <Link to="/jakarta" className="home-btn103 button">
              <span className="home-text049">
                <span>17 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image08"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/jakarta-500w.webp"
              className="home-image09"
            />
          </div>
        </div>
        <div className="home-row3">
          <div className="home-manado">
            <Link to="/manado" className="home-btn104 button">
              <span className="home-text052">
                <span>22 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image10"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/manado-500w.webp"
              className="home-image11"
            />
          </div>
          <div className="home-makasar">
            <Link to="/makasar" type="button" className="home-btn105 button">
              <span className="home-text055">
                <span>22 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image12"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/makassar-500w.webp"
              className="home-image13"
            />
          </div>
        </div>
        <div className="home-row4">
          <div className="home-medan">
            <img
              alt="image"
              src="/RallyDoaNew/jogjakarta-500w.webp"
              className="home-image14"
            />
            <Link to="/solo" className="home-btn106 button">
              <span className="home-text058">1 Desember 2023</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image15"
              />
            </Link>
          </div>
          <div className="home-serpong">
            <img
              alt="image"
              src="/RallyDoaNew/serpong-500w.webp"
              className="home-image16"
            />
            <Link to="/serpong" className="home-btn107 button">
              <span className="home-text059">5 Desember 2023</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image17"
              />
            </Link>
          </div>
          <div className="home-samarinda">
            <Link to="/samarinda" className="home-btn108 button">
              <span className="home-text060">
                <span>8 Desember 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image18"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/samarinda-500w.webp"
              className="home-image19"
            />
          </div>
          <div className="home-balikpapan">
            <img
              alt="image"
              src="/RallyDoaNew/balikpapan-500w.webp"
              className="home-image20"
            />
            <Link to="/balikpapan" className="home-btn109 button">
              <span className="home-text063">
                <span>9 Desember 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image21"
              />
            </Link>
          </div>
          <div className="home-jayapura">
            <img
              alt="image"
              src="/RallyDoaNew/jayapura-500w.webp"
              className="home-image22"
            />
            <Link to="/jayapura" className="home-btn110 button">
              <span className="home-text066">
                <span>15 Desember 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image23"
              />
            </Link>
          </div>
          <div className="home-jogjakarta">
            <Link to="/batam" className="home-btn111 button">
              <span className="home-text069">10 Januari 2024</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image24"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/medan-500w.webp"
              className="home-image25"
            />
          </div>
        </div>
        <div className="home-row5">
          <div className="home-semarang">
            <img
              alt="image"
              src="/RallyDoaNew/semarang-500w.webp"
              className="home-image26"
            />
            <Link to="/semarang" className="home-btn112 button">
              <span className="home-text070">12 Januari 2024</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image27"
              />
            </Link>
          </div>
          <div className="home-surabaya">
            <img
              alt="image"
              src="/RallyDoaNew/surabaya-500w.webp"
              className="home-image28"
            />
            <Link to="/surabaya" className="home-btn2 button">
              <span className="home-text071">21 Januari 2024</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image29"
              />
            </Link>
          </div>
        </div>
      </div>
      <div id="rallydoa" className="home-rally-doa-large">
        <h1 className="home-text072">Rally Doa</h1>
        <div className="home-row11">
          <div className="home-bali1">
            <img
              alt="image"
              src="/RallyDoaNew/bali-500w.webp"
              className="home-image30"
            />
            <Link to="/bali" className="home-btn113 button">
              <span className="home-text073">
                <span>10 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image31"
              />
            </Link>
          </div>
          <div className="home-tasik1">
            <img
              alt="image"
              src="/RallyDoaNew/tasik-500w.webp"
              className="home-image32"
            />
            <Link to="/tasikmalaya" className="home-btn114 button">
              <span className="home-text076">
                <span>13 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image33"
              />
            </Link>
          </div>
          <div className="home-bandung1">
            <Link to="/bandung" className="home-btn115 button">
              <span className="home-text079">
                <span>14 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image34"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/bandung-500w.webp"
              className="home-image35"
            />
          </div>
        </div>
        <div className="home-row21">
          <div className="home-jakarta1">
            <Link to="/jakarta" className="home-btn116 button">
              <span className="home-text082">
                <span>17 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image36"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/jakarta-500w.webp"
              className="home-image37"
            />
          </div>
          <div className="home-manado1">
            <Link to="/manado" className="home-btn117 button">
              <span className="home-text085">
                <span>22 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image38"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/manado-500w.webp"
              className="home-image39"
            />
          </div>
          <div className="home-makasar1">
            <Link to="/makasar" className="home-btn118 button">
              <span className="home-text088">
                <span>22 November 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image40"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/makassar-500w.webp"
              className="home-image41"
            />
          </div>
        </div>
        <div className="home-row31">
          <div className="home-medan1">
            <img
              alt="image"
              src="/RallyDoaNew/jogjakarta-500w.webp"
              className="home-image42"
            />
            <Link to="/solo" className="home-btn119 button">
              <span className="home-text091">1 Desember 2023</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image43"
              />
            </Link>
          </div>
          <div className="home-serpong1">
            <img
              alt="image"
              src="/RallyDoaNew/serpong-500w.webp"
              className="home-image44"
            />
            <Link to="/serpong" className="home-btn120 button">
              <span className="home-text092">5 Desember 2023</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image45"
              />
            </Link>
          </div>
          <div className="home-samarinda1">
            <Link to="/samarinda" className="home-btn121 button">
              <span className="home-text093">
                <span>8 Desember 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image46"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/samarinda-500w.webp"
              className="home-image47"
            />
          </div>
        </div>
        <div className="home-row41">
          <div className="home-balikpapan1">
            <img
              alt="image"
              src="/RallyDoaNew/balikpapan-500w.webp"
              className="home-image48"
            />
            <Link to="/balikpapan" className="home-btn122 button">
              <span className="home-text096">
                <span>9 Desember 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image49"
              />
            </Link>
          </div>
          <div className="home-jayapura1">
            <img
              alt="image"
              src="/RallyDoaNew/jayapura-500w.webp"
              className="home-image50"
            />
            <Link to="/jayapura" className="home-btn123 button">
              <span className="home-text099">
                <span>15 Desember 2023</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image51"
              />
            </Link>
          </div>
          <div className="home-jogjakarta1">
            <Link to="/batam" className="home-btn124 button">
              <span className="home-text102">10 Januari 2024</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image52"
              />
            </Link>
            <img
              alt="image"
              src="/RallyDoaNew/medan-500w.webp"
              className="home-image53"
            />
          </div>
        </div>
        <div className="home-row51">
          <div className="home-semarang1">
            <img
              alt="image"
              src="/RallyDoaNew/semarang-500w.webp"
              className="home-image54"
            />
            <Link to="/semarang" className="home-btn125 button">
              <span className="home-text103">12 Januari 2024</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image55"
              />
            </Link>
          </div>
          <div className="home-surabaya1">
            <img
              alt="image"
              src="/RallyDoaNew/surabaya-500w.webp"
              className="home-image56"
            />
            <Link to="/surabaya" className="home-btn21 button">
              <span className="home-text104">21 Januari 2024</span>
              <img
                alt="image"
                src="/external/pastedimage-ufqa-200h.png"
                className="home-image57"
              />
            </Link>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;

import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./header.css";

const Header = (props) => {
  return (
    <div className={`header-header ${props.rootClassName} `}>
      <div className="header-logo">
        <Link to="/" className="header-navlink">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="header-image"
          />
        </Link>
      </div>
      <div className="header-button">
        <Link to="/" className="header-home">
          Beranda
        </Link>
        <a href={props.profile} className="header-profile">
          Profil
        </a>
        <a href={props.visimisi} className="header-visi-misi">
          Visi &amp; Misi
        </a>
        <a href={props.rallydoa} className="header-rally-doa">
          Rally Doa
        </a>
        <a href={props.hubkami} className="header-hub-kami">
          Hubungi Kami
        </a>
        <div data-thq="thq-dropdown" className="header-thq-dropdown list-item">
          <div
            data-thq="thq-dropdown-toggle"
            className="header-dropdown-toggle"
          >
            <svg viewBox="0 0 1024 1024" className="header-icon">
              <path
                d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
                className=""
              ></path>
            </svg>
          </div>
          <ul data-thq="thq-dropdown-list" className="header-dropdown-list">
            <li data-thq="thq-dropdown" className="header-dropdown list-item">
              <Link to="/" className="">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="header-dropdown-toggle1"
                >
                  <span className="header-text">Beranda</span>
                </div>
              </Link>
            </li>
            <li data-thq="thq-dropdown" className="header-dropdown1 list-item">
              <a href={props.profile} className="">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="header-dropdown-toggle2"
                >
                  <span className="header-text1">Profile</span>
                </div>
              </a>
            </li>
            <li data-thq="thq-dropdown" className="header-dropdown2 list-item">
              <a href={props.visimisi} className="">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="header-dropdown-toggle3"
                >
                  <span className="header-text2">Visi &amp; Misi</span>
                </div>
              </a>
            </li>
            <li data-thq="thq-dropdown" className="header-dropdown3 list-item">
              <a href={props.rallydoasmall} className="">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="header-dropdown-toggle4"
                >
                  <span className="header-text3">Rally Doa</span>
                </div>
              </a>
            </li>
            <li data-thq="thq-dropdown" className="header-dropdown4 list-item">
              <div
                data-thq="thq-dropdown-toggle"
                className="header-dropdown-toggle5"
              >
                <a href="/" className="header-text4">
                  Hubungi Kami
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  image_alt: "image",
  rootClassName: "",
  Home: "Beranda",
  Hub_Kami: "Hubungi Kami",
  image_src: "/Asset/logo-200h.webp",
  profile: "#profile",
  visimisi: "#visimisi",
  rallydoa: "#rallydoa",
  rallydoasmall: "#rallysmall",
};

Header.propTypes = {
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
  Home: PropTypes.string,
  Hub_Kami: PropTypes.string,
  image_src: PropTypes.string,
  profile: PropTypes.string,
  visimisi: PropTypes.string,
  rallydoa: PropTypes.string,
  rallydoasmall: PropTypes.string,
};

export default Header;

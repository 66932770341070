import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../../components/header';
import ImageDisplay from '../../components/imagegallery';
import Footer from '../../components/footer';
import './jakarta.css';
import ImageSlider from '../../components/image-slider';
import VideoEmbed from '../../components/VideoEmbed';

const asset = ['/Asset/balikbanner.webp', '/gereja/BALIKPAPAN.webp'];

const Balikpapan = (props) => {
  useEffect(() => {
    // Function to scroll to the top of the page
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Scroll to the top when the component mounts (page loads)
    scrollToTop();
  }, []); // Empty dependency array to only run this effect once

  const ytVidId = 'EE1tWlKi33E';

  return (
    <div className='jakarta-container'>
      <Helmet>
        <title> Balikpapan - Indonesia di Hatiku</title>
        <meta
          property='og:title'
          content='Jakarta - Indonesia di Hatiku'
        />
      </Helmet>
      <Header
        rootClassName='header-root-class-name'
        profile={'/'}
        visimisi={'/'}
        rallydoa={'/'}
        rallydoasmall={'/'}
      ></Header>
      <div className='jakarta-banner'>
        <ImageSlider
          image1={'/Asset/balikbanner.webp'}
          image2={'/gereja/BALIKPAPAN.webp'}
        ></ImageSlider>
      </div>
      <VideoEmbed videoId={ytVidId} />
      <ImageDisplay
        directory='balikpapan'
        numImages={29}
      />
      <Footer rootClassName='footer-root-class-name'></Footer>
    </div>
  );
};

export default Balikpapan;

import React from 'react';
import './VideoEmbed.css';

export default function VideoEmbed({ videoId }) {
  return (
    <div className='video-container'>
      <iframe
        title='YouTube Video'
        width='560'
        height='315'
        src={`https://www.youtube.com/embed/${videoId}`}
        allowFullScreen
      ></iframe>
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./style.css";
import Home from "./views/home";
import Prototype from "./views/prototype";
import Jakarta from "./views/detailkota/jakarta";
import NotFound from "./views/not-found";
import Bali from "./views/detailkota/bali";
import Tasik from "./views/detailkota/tasik";
import Bandung from "./views/detailkota/bandung";
import Manado from "./views/detailkota/manado";
import Makasar from "./views/detailkota/makasar";
import Serpong from "./views/detailkota/serpong";
import Samarinda from "./views/detailkota/samarinda";
import Balikpapan from "./views/detailkota/balikpapan";
import Jayapura from "./views/detailkota/jayapura";
import Semarang from "./views/detailkota/semarang";
import Surabaya from "./views/detailkota/surabaya";
import Solo from "./views/detailkota/solo";
import Batam from "./views/detailkota/batam";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Prototype} exact path="/prototype" />
        <Route component={Bali} exact path="/bali" />
        <Route component={Tasik} exact path="/tasikmalaya" />
        <Route component={Bandung} exact path="/bandung" />
        <Route component={Jakarta} exact path="/jakarta" />
        <Route component={Manado} exact path="/manado" />
        <Route component={Makasar} exact path="/makasar" />
        <Route component={Solo} exact path="/solo" />
        <Route component={Serpong} exact path="/serpong" />
        <Route component={Samarinda} exact path="/samarinda" />
        <Route component={Balikpapan} exact path="/balikpapan" />
        <Route component={Jayapura} exact path="/jayapura" />
        <Route component={Batam} exact path="/batam" />
        <Route component={Semarang} exact path="/semarang" />
        <Route component={Surabaya} exact path="/surabaya" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));

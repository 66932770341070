import React, { useState } from 'react';

import "./imagegallery.css";
const ImageDisplay = ({ directory, numImages }) => {
  // Generate an array of image names based on numImages
  const imageNames = Array.from({ length: numImages }, (_, i) => `${i + 1}.webp`);

  // State to handle the enlarged image
  const [enlargedImage, setEnlargedImage] = useState(null);

  return (
    <div className="container">
      {imageNames.map((imageName, index) => (
        <img 
          key={index} 
          src={`${process.env.PUBLIC_URL}/gallery/${directory}/${imageName}`} 
          alt="description"
          onClick={() => setEnlargedImage(`${process.env.PUBLIC_URL}/gallery/${directory}/${imageName}`)}
        />
      ))}
      {enlargedImage && (
        <div className="modal" onClick={() => setEnlargedImage(null)}>
          <img src={enlargedImage} alt="description" />
          <p className="modal-text">click anywhere to close</p>
        </div>
      )}
    </div>
  );
}

export default ImageDisplay;

import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import Header from '../../components/header';
import Footer from '../../components/footer';
import VideoEmbed from '../../components/VideoEmbed';
import './jakarta.css';

const Bandung = (props) => {
  useEffect(() => {
    // Function to scroll to the top of the page
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Scroll to the top when the component mounts (page loads)
    scrollToTop();
  }, []); // Empty dependency array to only run this effect once

  const ytVidId = 'FJSoEJzQglg';

  return (
    <div className='jakarta-container'>
      <Helmet>
        <title> Bandung - Indonesia di Hatiku</title>
        <meta
          property='og:title'
          content='Jakarta - Indonesia di Hatiku'
        />
      </Helmet>
      <Header
        rootClassName='header-root-class-name'
        profile={'/'}
        visimisi={'/'}
        rallydoa={'/'}
        rallydoasmall={'/'}
      ></Header>
      <div className='jakarta-banner'>
        <img
          alt='image'
          src='/gereja/BANDUNG.webp'
          className='jakarta-image'
        />
      </div>
      <VideoEmbed videoId={ytVidId} />
      <Footer rootClassName='footer-root-class-name'></Footer>
    </div>
  );
};

export default Bandung;
